import { Pipe, PipeTransform } from '@angular/core';
import * as fn from '../srv/debug.service';

@Pipe({ name: 'formatDateCalendar' })
export class FormatDateCalendarPipe implements PipeTransform {

    transform( text: string, mode: string ): string {
        let retVal = '(INVALID)';

        if (text) {
            if ( mode === 'from' ) {
                const splitted  = text.split('/'),          // dal formato en-US esempio "11/2/2017"
                      day       = parseInt(splitted[1], 10),
                      month     = parseInt(splitted[0], 10),
                      year      = parseInt(splitted[2], 10);
                if ( year > 1900 && year < 2500 && month > 0 && month < 13 && day > 0 && day < 32 ) {
                    retVal = this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;            // al formato it-IT esempio "02/11/2017"
                } // altrimenti resta '(INVALID)'
            } else if ( mode === 'to' && text.length === 10 ) {
                const splitted  = text.split('/'),          // dal formato it-IT esempio "02/12/2017"
                      day       = parseInt(splitted[0], 10),
                      month     = parseInt(splitted[1], 10),
                      year      = parseInt(splitted[2], 10);
                if ( year > 1900 && year < 2500 && month > 0 && month < 13 && day > 0 && day < 32 ) {
                    retVal = `${month}/${day}/${year}`;                                                 // al formato en-US esempio "12/2/2017"
                } // altrimenti resta '(INVALID)'
            } else {
                console.error('mode: ', mode, 'text: ', text);
            }
        } else {
            console.error('text: ', text);
        }

        return retVal;
    }

    private _to2digit( n: number ): string {
        return ( '00' + n ).slice(-2);
    }
}
