import {Component, Input} from '@angular/core';

@Component({
    selector: 'spinner-cmp',
    styleUrls: ['spinner.scss'],
    template: `
        <div class="spinnerloader">
            <div class="loader-spinner">
                <img alt="loading..." src="/assets/img/spinner.gif" />
            </div>
            <div class="loader-background"></div>
        </div>
    `
})

export class SpinnerComponent {}
