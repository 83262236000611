import { Injectable              } from '@angular/core';
import { environment             } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProfileService          } from './profile.service';
import { SpinnerService          } from '../shared/spinner/spinner.service';
import { Observable              } from 'rxjs/internal/Observable';
import { messages                } from '../../environments/messages';
import * as fn from '../srv/debug.service';


declare var $: any;

interface Parameters  {
    // PARAMETRO     TIPO
      sPath?:         string     // path dell'url backend dell'azione da richiamare
    , fRes?:          Function   // callback su risposta positiva
    , fErr?:          Function   // callback su risposta negativa
    , fEnd?:          Function   // callback eseguita alla fine (se non ci sono errori)
    , sActionType?:   string     // tipo di azione che eseguirà il backend
    , oActionParams?: any        // dati necessari per poter eseguire l'azione
    , bAlert?:        boolean    // abilitazione alert per errori
}

@Injectable()
export class CommonService {

    constructor(
        private http:           HttpClient,
        private profileService: ProfileService,
        private spinnerService: SpinnerService
    ) {}

    /**
     * function sendToBackend
     * @param {string} httpMethod : http method ('get' o 'post')
     * @param {object} anonym     : oggetto anomino con più parametri
     *      sPath           path dell'url backend dell'azione da richiamare (es. '/settings/period-get')
     *      fRes            callback su risposta positiva
     *      fErr            callback su risposta negativa
     *      fEnd            callback eseguita alla fine (se non ci sono errori)
     *      sActionType     tipo di azione che eseguirà il backend ('get', 'ins', 'mod', 'del', 'mov')
     *      oActionParams   dati necessari per poter eseguire l'azione
     *      bAlert          abilitazione alert per errori
     */
    sendToBackend(
        httpMethod: string = 'get', // http method
        // oggetto unico con più parametri
        {   // PARAMETRO     DEFAULT
              sPath         = ''
            , fRes          = () => {}
            , fErr          = () => {}
            , fEnd          = () => {}
            , sActionType   = ''
            , oActionParams = {}
            , bAlert        = false
        }: Parameters
    ) {

        this.spinnerService.show(true);
        let url = environment.srvUrl + sPath + sActionType.toLowerCase(); // costruisco la stringa della url che passerò al backend

        if ( httpMethod === 'get' ) {
            let prefix = '?';
            Object.keys(oActionParams).forEach( (key, keyIndex) => { // del parametro "actionParams" concateno tutte le proprietà
                if ( keyIndex > 0 )                              { prefix  = '&'; }
                if ( typeof oActionParams[key] !== 'undefined' ) { url    += ( prefix + key + '=' + oActionParams[key] ); } // VERSIONE CON CONTROLLO SE UNDEFINED
                // url += ( prefix + key + '=' + actionParams[key] ); // VERSIONE SENZA CONTROLLI
            });
        }

        // quando ricevo una risposta dal backend eseguo sempre determinate azioni e richiamo una specifica funzione di callback
        const fResponseOK  = resOK => { this.profileService.refreshToken(resOK.headers); this.spinnerService.show(false); fRes(resOK); },
              fResponseKO  = errKO => { this.profileService.serviceError(errKO, bAlert); this.spinnerService.show(false); fErr(errKO); },
              fResponseEND = ()    => { fEnd(); }
        ;

        let   requestSentToBackend: Observable<any>;
        const options: any = { observe: 'response' };

        if (        httpMethod === 'get') {

            // per risolvere BUG di Angular (https://stackoverflow.com/questions/45428842/angular-url-plus-sign-converting-to-space)
            url = url.replace(/\+/gi, '%2B'); // sostituisco manualmente il "+" con "%2B"

            options.headers      = new HttpHeaders({ 'Authorization': `Bearer ${ localStorage.getItem('session_token') }` });
            requestSentToBackend = this.http.get( url, options );

        } else if ( httpMethod === 'post') {

            options.headers      = new HttpHeaders({
                'Authorization': `Bearer ${ localStorage.getItem('session_token') }`
                , 'Content-Type' : 'application/json'
            });
            options.responseType = ( oActionParams.advUserInputs && oActionParams.advUserInputs.excel ) ||
            ( oActionParams.ediUserInputs && oActionParams.ediUserInputs.excel )
                ? 'arraybuffer' : 'json';
            requestSentToBackend = this.http.post( url, oActionParams, options );

        } else if ( httpMethod === 'upload') {

            options.headers      = new HttpHeaders({
                'Authorization': `Bearer ${ localStorage.getItem('session_token') }`
                , 'Content-Type' : 'multipart/form-data'
            });
            options.responseType = 'json';
            requestSentToBackend = this.http.post( url, oActionParams, options );

        }

        requestSentToBackend.subscribe( fResponseOK, fResponseKO, fResponseEND );

    }

    get(parameters: any) {
        this.sendToBackend('get', parameters);
    }

    post(parameters: any) {
        this.sendToBackend('post', parameters);
    }

    upload(parameters: any) {
        this.sendToBackend('upload', parameters);
    }


    notifyToUser(
        response,
        alertService,
        type1,
        type2                   = '',
        bSuppressSuccessMsg     = false,
        defaultSuccessMsg       = 'Operation successfully executed',
        defaultErrorMsg         = 'An error has occurred'
    ) {

        const { body }          = response;
        let nRetVal, vErrorMessageDesc;

        if ( (typeof body) === 'number' ) {
            nRetVal             = Number.parseInt( body, 10 ) || -1;
        } else {
            fn.debug( !Number.parseInt( body.nRetVal, 10 ), 'Error body.nRetVal', body.nRetVal);
            nRetVal             = Number.parseInt( body.nRetVal, 10 ) || -1;
            vErrorMessageDesc   = body.vErrorMessageDesc;
        }

        const oMessage          = ( messages[type1] && messages[type1][type2] ) || messages[type1] || {},
              bRetValOK         = nRetVal > 0,
              sMsgToDisplay     = bRetValOK ?
                  ( ( oMessage && oMessage.success && oMessage.success[1] ) || defaultSuccessMsg ) :
                  ( vErrorMessageDesc || ( oMessage && oMessage.error && oMessage.error[ nRetVal * -1 ] ) || defaultErrorMsg )
        ;

        if ( sMsgToDisplay ) {
            if ( bRetValOK ) {
                if ( !bSuppressSuccessMsg ) {
                    alertService.showNotification( sMsgToDisplay, 'success');
                }
            } else {
                alertService.showNotification( sMsgToDisplay, 'danger');
            }
        }

    }

}

/* TODO da finire
export class AncestorObject {
    // serve non solo per settare i valori di default, ma per prevedere anche un oggetto passato come parametro al costruttore
    // se l'oggetto passato ha meno proprietà di quelle previste, le rimanenti le mette il costruttore con i valori di default,
    // se ne ha di più vengono prese solo quelle elencate tra le proprietà iniziali di questo oggetto

    constructor(...paramsArray) {
        const defaults = {
              'boolean'  : false
            , 'number'   : 0
            , 'string'   : ''
            , 'object'   : null
        };
        const paramsObject = {};
        Object.assign(paramsObject, paramsArray);
        Object.keys(this).forEach( k => {

            k = defaults[typeof k];
        });

    }

    a(
        {
            DAYPART_GROUP_COD    = 0,
            DAYPART_GROUP_DESC   = ''
        } = {
            DAYPART_GROUP_COD:   0,
            DAYPART_GROUP_DESC:  ''
        }
    ) {
        this.DAYPART_GROUP_COD   = DAYPART_GROUP_COD;
        this.DAYPART_GROUP_DESC  = DAYPART_GROUP_DESC;
    }

}
*/
