import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    titlePrefixProject = environment.titlePrefixProject;
    titleProject = environment.titleProject;
    test: Date = new Date();
}
