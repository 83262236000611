import { Component, OnInit, Renderer, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar-routes.config';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProfileService } from '../.././srv/profile.service';
import * as fn from '../../srv/debug.service';

var misc:any ={
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
declare var $: any;
@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
    private listTitles: any;
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;

    private username: string;
    private name: string;
    private surname: string;

    // @ViewChild("navbar-cmp") button;

    constructor(location:Location, private renderer : Renderer, private element : ElementRef, private profileService: ProfileService, private router: Router) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.username = profileService.getUsername();
        this.name = profileService.getName();
        this.surname = profileService.getSurname();
    }

    ngOnInit(){
        this.listTitles = ROUTES;

        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if($('body').hasClass('sidebar-mini')){
            misc.sidebar_mini_active = true;
        }
        $('#minimizeSidebar').click(function(){
            var $btn = $(this);

            if(misc.sidebar_mini_active == true){
                $('body').removeClass('sidebar-mini');
                misc.sidebar_mini_active = false;

            }else{
                setTimeout(function(){
                    $('body').addClass('sidebar-mini');

                    misc.sidebar_mini_active = true;
                },300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            var simulateWindowResize = setInterval(function(){
                window.dispatchEvent(new Event('resize'));
            },180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function(){
                clearInterval(simulateWindowResize);
            },1000);
        });
    }
    isMobileMenu(){
        if($(window).width() < 991){
            return false;
        }
        return true;
    }
    sidebarToggle(){
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];

        if(this.sidebarVisible == false){
            setTimeout(function(){
                toggleButton.classList.add('toggled');
            },500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    getTitle(){
        let titlee = this.location.prepareExternalUrl(this.location.path());
        if(titlee.charAt(0) === '#'){
            titlee = titlee.slice( 1 );
        }

        const allMenuItems = [];
        /* versione ES2017 non riconosciuta dal compilatore
        Object.values(this.listTitles).forEach( value => {
            allMenuItems.push(...value); // non c'è nessun errore ma il compilatore non lo riconosce
        }); // values è supportato dalla ES2017
        */
        for (const property in this.listTitles) {
            if (this.listTitles.hasOwnProperty(property)) {
                allMenuItems.push(...this.listTitles[property]);
            }
        }

        return allMenuItems.filter( item => { return item.path === titlee } )[0].title || 'Dashboard';
        /*
        for(var item = 0; item < this.listTitles.length; item++){
            if(this.listTitles[item].path === titlee){
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
        */
    }
    getPath(){
        return this.location.prepareExternalUrl(this.location.path());
    }

    logout(){
        this.router.navigate(['pages/login']);
        this.profileService.logout();
    }
}
