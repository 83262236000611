import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatDateDB' })
export class FormatDateDBPipe implements PipeTransform{
    transform(text: string, mode: string){
        let retVal: string='';

        if (text != null && text != 'undefined' && text != '') {
            if(mode=='from' && text.length == 8) {
                retVal = `${text.substr(6, 2)}/${text.substr(4, 2)}/${text.substr(0, 4)}`;
            }else if(mode=='to' && text.length == 10) {
                var res = text.split("/");
                var day = res[0];
                var month = res[1];
                var year = res[2];
                retVal = `${year}${month}${day}`;
            }
        }

        return retVal;
    }
}
