import { Injectable     } from '@angular/core';
import { environment    } from '../../environments/environment';
import * as moment from 'moment';

declare var $: any;

@Injectable()
export class DebugService {}

export function debug( isDebugEnabled: any, varName: string, varValue: any ) {
    if ( environment.debug && isTruthyNotEmpty(isDebugEnabled) ) {
        console.log('"' + varName + '" (' + typeof(varValue) + '): ' + JSON.stringify(varValue, null, '\t'));
    }
}

export function log( isDebugEnabled: any, varValue: any ) {
    if ( environment.debug && isTruthyNotEmpty(isDebugEnabled) ) {
        console.log(JSON.stringify(varValue, null, '\t'));
    }
}

// funzione comoda solo per controllare se la variabile è vuota
export function empty( varToCheck: any ): boolean {
    let emptyVar = true;
    switch ( typeof(varToCheck) ) {
        case 'string':                              { emptyVar = varToCheck === '';                     break; }
        case 'number':                              { emptyVar = varToCheck === 0;                      break; }
        case 'object':                              {
            if        ( varToCheck == null )        { emptyVar = true;                                  break;
            } else if ( Array.isArray(varToCheck) ) { emptyVar = varToCheck.length < 1;                 break;
            } else    /* altrimenti è un oggetto */ { emptyVar = Object.keys(varToCheck).length === 0;  break; }
        }
        default: { debug(1, 'emptyVar? var', varToCheck); } // finisce qui se è undefined, boolean o una funzione
    }
    return emptyVar;
}

// funzione comoda solo per controllare se la variabile è diversa da ( '', 0, false, null, undefined, [], {} )
export function isTruthyNotEmpty( varToCheck: any ): boolean {
    return !( !varToCheck || ( typeof varToCheck === 'object' && Object.keys(varToCheck).length < 1 ) );
    // soluzione alternativa ma più lunga: ( typeof varToCheck === 'object' ) ? ( !!varToCheck && Object.keys(varToCheck).length > 0 ) : !!varToCheck;
}

// trasforma una data "20150228" in "28 Feb"
export function translate( date: string ): string {
    const momentDate = moment(date, 'YYYYMMDD');
    return momentDate.isValid() ? momentDate.format('D MMM') : '(INVALID)';
}

// controlla se è un numero e se è positivo, poi aggiunge il . (punto) ogni tre numeri positivi
export function formatPosIntNum(numero: any): string {
    if ( numero != null ) {
        // Elimino i caratteri che non sono numeri (lascio il segno meno e il punto)
        let stringNum = numero.toString().replace(/[^0-9\.\-]?/gi, '');
        if ( !isNaN(parseInt(stringNum, 10)) && parseInt(stringNum, 10) > -1 ) {
            // Aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
            if ( stringNum.length > 3 ) { stringNum = stringNum.replace(/\B(?=(?:\d{3})+(?!\d))/g, '.'); }
            return stringNum;
        } else { return ''; }
    } else { return ''; }
}

// mantiene un esatto numero di spazi prima e dopo la virgola per numeri percentuali (es. 0,1 diventa " 0,10" )
export function formatPercNum(numAsString: string, spacesBeforePoint: number, spacesAfterPoint: number): string {
    let retVal    = '';
    const numParsed = parseFloat(numAsString);
    if ( !isNaN(numParsed) ) {
        retVal                  += numParsed.toFixed(spacesAfterPoint);
        const arrString         = retVal.split('.');                    // separo parte intera e parte decimale
        const numBeforePoint    = parseInt(arrString[0], 10);           // la parte intera sarà sempre al massimo di due cifre
        retVal                  =  numBeforePoint + ',' + arrString[1];
        // if (numBeforePoint < 100) { retVal += ' ' }                     // se la parte intera è <100 aggiungo uno spazio vuoto dopo
        if (numBeforePoint < 10)  { retVal = ' ' + retVal }             // se la parte intera è 0-9 aggiungo uno spazio vuoto prima
        retVal += '%';
    }
    return  retVal;
}

// converte un array in un'unica stringa con gli elementi separati da virgole
export function arrayToString( arrayToConvert: any ): string {
    let result = '';
    if ( Array.isArray(arrayToConvert) ) {
        for ( let i = 0; i < arrayToConvert.length; i++ ) {
            if ( i > 0 && i < arrayToConvert.length )   { result += ','; }
            if ( arrayToConvert[i] == null )            { debug(1, 'Errore in arrayToString: ', arrayToConvert[i]);
            } else                                      { result += arrayToConvert[i]; }
        }
    }
    return result;
}

// funzione per controllare se due oggetti sono uguali (fino al primo livello di profondità)
export function areObjEquals( a: object, b: object ): boolean {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if ( aProps.length !== bProps.length ) { return false; }
    for ( let i = 0; i < aProps.length; i++ ) {
        const propName = aProps[i];
        if ( a[propName] !== b[propName] ) {
            return false;
        }
    }
    return true;
}

// per copiare l'intero contenuto di un oggetto in una nuova variabile a se stante (completamente indipendente dall'originale)
export function copyObj(obj) {
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}

// funzione per controllare se un oggetto è presente in un array (controllando le proprietà solo fino al primo livello di profondità)
export function objIsInArray( obj: object, arr: any ): boolean {
    if ( Array.isArray(arr) ) {
        return  arr.some( element => areObjEquals(element, obj) );
    } else {
        console.error('Error in objIsInArray: no array ', arr);
        return false;
    }
}

/*  funzione per eseguire un setTimeout senza perdere il "this" del contesto
    esempio di utilizzo:
    afterTimeout( _ => {                // il carattere "_" è un possibile parametro, meglio impostarlo per evitare un errore
        console.log(this.prop1);        // azioni da eseguire dopo il timeout (normalmente dentro a "setTimeout")
    }, 2000);                           // come ultimo parametro i millisecondi che devono passare prima di eseguire le azioni
export function afterTimeout( f: Function, ms: number ): void {
    return (function() {
        setTimeout(() => f.apply(this, arguments), ms)
    })(); // esegue la funzione "f" dopo "ms" millisecondi applicando il "this" originario e gli eventuali "arguments" passati
}
DISABILITATA PER INCOMPATIBILITA' CON ANGULAR 8 (SENZA ES217) */

// funzione per ottenere i gradi in cui è rivolto un elemento "event.target"
export function getDegrees(obj) {
    const matrix =  obj.css('-webkit-transform') ||
                    obj.css('-moz-transform')    ||
                    obj.css('-ms-transform')     ||
                    obj.css('-o-transform')      ||
                    obj.css('transform');
    let angle    = 0;
    if (matrix !== 'none') {
        const values = matrix.split('(')[1].split(')')[0].split(',');
        angle        = Math.round(Math.atan2(values[1], values[0]) * (180 / Math.PI));
    }
    return (angle < 0) ? angle + 360 : angle;
}

export function sortBy( arr: object[], properties: any, desc: boolean = false ) {
    // debug( Array.isArray(properties), 'array', this); debug(1, 'params', properties);
    const
        toStr                = anyVar     => ( anyVar || 0 ).toString(),         // per avere stringhe simili da confrontare (i falsy vengono uniformati)
        uniformRes           = res        => res < 0 ? -1 : ( res > 0 ? 1 : 0 ), // serve per uniformare i risultati del localeCompare (-1 0 +1)
        setLocaleCompare     = ( x1, x2 ) => x1.localeCompare( x2, 'en', { numeric: true, sensitivity: 'base' } ),
        compare              = ( e1, e2 ) => uniformRes( setLocaleCompare( toStr(e1), toStr(e2) ) ), // restituisce un valore uniformato per la comparazione di stringhe
        validateString       = str        => ( typeof str === 'string' ) && str || ('err: "' + JSON.stringify(str) + '"'),  // controlla se è una stringa e che non sia vuota
        validateProperty     = ( elToDebug, prop )  => {
            debug( typeof elToDebug[prop] === 'undefined', 'ERROR: proprietà ' + prop + ' non trovata in', elToDebug );
            return elToDebug[prop] || 0
        },
        compareAllProperties = ( obj1, obj2, props = [] ) => {
            let retVal          = 0,
                obj1PropValue   = '',
                obj2PropValue   = ''
            ;
            props.forEach( property => {
                property        = validateString(property);
                obj1PropValue   = validateProperty(obj1, property);
                obj2PropValue   = validateProperty(obj2, property);
                retVal          = retVal || compare ( desc ? obj2PropValue : obj1PropValue, desc ? obj1PropValue : obj2PropValue );
            });
            return retVal
        }
    ;

    if ( !Array.isArray(properties) ) { properties = [properties]; } // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )

    arr.sort( ( a, b ) => compareAllProperties( a, b, properties ) );
}

export function groupBy( arr: object[], properties: any ) {
    // polyfill per mancanza di "...new Set(" PROBLEMA INTRODOTTO DA ANGULAR VERSIONE 8
    const set = ao => ao.filter( (value, index, self) => self.indexOf(value) === index);

    // se non è un array, lo converto in array ( per chiamare il groupBy con un solo parametro di tipo string )
    if ( !Array.isArray(properties) ) { properties = [properties]; }
    return [ set( // un elenco di valori univoci
        arr.map( o => { // delle sole proprietà elencate in "properties"
            let newObj = {};
            properties.forEach( p => { newObj[p] = o[p] }); // assegno al nuovo oggetto solo le proprietà elencate
            return JSON.stringify(newObj)                   // trasformo l'oggetto in stringa così che Set() possa lavorare correttamente
        })
    )].map( element => JSON.parse(element.toString()) );    // ritrasformo gli elementi da stringhe a oggetti
}



export function assignOnlyValidKeysFromTo( fromObj: object = {}, toObj: object = {}, debugVar: boolean = false ): any {
    debug(debugVar, 'fromObj', fromObj);
    debug(debugVar, 'toObj PRIMA', toObj);
    const defaultValues = {
          'boolean' : false
        , 'number'  : 0
        , 'string'  : ''
        , 'array'   : []
        , 'object'  : {}
    };
    let defaultValue, toObjType;
    Object.keys(toObj).forEach( key => {                                        // per ogni chiave dell'oggetto di destinazione
        toObjType    = Array.isArray(toObj[key]) ? 'array' : typeof toObj[key]; // mi segno il tipo della proprietà
        defaultValue = defaultValues[toObjType];                                // mi segno quale sarebbe il valore di default per il tipo corrispondente
        // todo convertire la singola proprietà proveniente da "fromObj" nel formato previsto da "toObj"
        toObj[key]   = fromObj[key] != null                                     // e assegno all'oggetto di destinazione (alla proprietà su cui sto ciclando)
                       ? fromObj[key]                                           // la corrispondente proprietà (se esiste) dell'oggetto di partenza
                       : ( defaultValue != null ? defaultValue : null );        // altrimenti il valore di default o null
    });
    debug(debugVar, 'toObj DOPO', toObj);
    return toObj
}

// add/remove/replace solo degli elementi diversi nell'arrayToCheck rispetto all'arrayToUpdate (per la proprietà 'keyProperty')
// questa funzione serve per aggiornare un array solo per la parte effettivamente modificata e non tutto nell'insieme (così non si ricarica nell'interfaccia)
export function updateArray( {
        arrayToUpdate= [],
        arrayToCheck = [],
        keyProperty  = '',
        sortProperty = ''
    }
): void {

    const d         = false;
    debug(d, 'params', { arrayToUpdate, arrayToCheck, keyProperty, sortProperty });
    arrayToUpdate.forEach( ( objectToUpdate, indexObjectToUpdate ) => {
        debug(d, 'arrayToUpdate index: ' + indexObjectToUpdate + ' element', objectToUpdate);
        const elementFound = arrayToCheck.find( objectToCheck => objectToCheck[keyProperty] === objectToUpdate[keyProperty] ); // se i due elementi sono uguali per la "keyProperty"
        if (!elementFound) { // se l'elemento di arrayToUpdate non è stato trovato in arrayToCheck
            // REMOVE (-)
            arrayToUpdate.splice(indexObjectToUpdate, 1); // allora lo elimino
        } else { // altrimenti se lo trovo
            // REPLACE (-+)
            Object.assign( objectToUpdate, elementFound ); // copio tutto l'elemento (sia che ci siano differenze, sia che non ce ne siano)
        }
    });
    arrayToCheck.forEach( ( objectToCheck, indexObjectToCheck ) => {
        debug(d, 'arrayToCheck index: ' + indexObjectToCheck + ' element', objectToCheck);
        const elementFound = arrayToUpdate.find( objectToUpdate => objectToUpdate[keyProperty] === objectToCheck[keyProperty] ); // se i due elementi sono uguali per la "keyProperty"
        if (!elementFound) { // solo se l'elemento di arrayToCheck non è stato trovato in arrayToUpdate
            // ADD (+)
            arrayToUpdate.push(objectToCheck); // allora lo inserisco (in fondo a arrayToUpdate)
        }
    });
    sortBy(arrayToUpdate, sortProperty); // riordino l'arrayToUpdate in base a una specifica proprietà dopo che è stata probabilmente cambiata da arrayToUpdate
    // todo riordinare solo gli elementi in cui è cambiato il criterio di sort

}

/**
 * function getOrSetNestedKey
 *
 * Sets a value of nested key string descriptor inside a Object.
 * It changes the passed object.
 * Ex:
 *    var obj = { a: { b: { c: 'initial' } } };
 *    getOrSetNestedKey('set', obj, ['a', 'b', 'c'], 'changed-value');
 *    console.log(JSON.stringify(obj));
 *
 * @param {string}   sType        - type of action to be taken (default 'get')
 * @param {Object}   oObject      - Object to set the nested key
 * @param {string[]} asPath       - An array of strings to describe the path (Ex: ['a', 'b', 'c'])
 * @param {*}        vValueToSet  - Any value
 * @param {Boolean}  debugOn      - set debug
 */
export function getOrSetNestedKey ( sType = 'get', oObject = {}, asPath: string[] = [], vValueToSet = '', debugOn = false ) {
    // controlli di validità
    if ( !Array.isArray(asPath)    || asPath.length < 1   ) { debug( debugOn, 'invalid array', asPath);  return }
    if ( !(typeof oObject === 'object') || !oObject[asPath[0]] ) { debug( debugOn, '"' + asPath[0] + '" does not exist on ', oObject ); return }

    if ( asPath.length === 1) {             // caso base
        if ( sType === 'get' ) {
            return oObject[ asPath[0] ]
        } else if ( sType === 'set' ) {
            oObject[ asPath[0] ] = vValueToSet; // assegna il valore vValueToSet all'ultima chiave valida indicata in asPath
            return                              // esce dalla ricorsione
        }
    }

    // ricorsione
    return getOrSetNestedKey( sType, oObject[ asPath[0] ], asPath.slice(1), vValueToSet )
}

export function setNestedKey ( oObject = {}, asPath = [], vValueToSet = '' ) {
    return getOrSetNestedKey ( 'set', oObject, asPath, vValueToSet );
}

export function getNestedKey ( oObject = {}, asPath = [] ) {
    return getOrSetNestedKey ( 'get', oObject, asPath );
}

export function fsCapitalize ( s: string ) {
    return (typeof s !== 'string') ? '' : s.charAt(0).toUpperCase() + s.slice(1)
}

