import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
    private visible = new BehaviorSubject<boolean>(false);
    currentStatus = this.visible.asObservable();

    constructor() {}

    show(status: boolean) {
        this.visible.next(status);
    }

}
