import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { LookupService } from '../../srv/lookup.service';
import { ProfileService } from '../../srv/profile.service';
import * as fn from '../../srv/debug.service';

/*
export interface ConfirmModel {
    title: string;
    periodsInput: any[];
}
*/

@Component({
    selector:    'modal-periods',
    templateUrl: 'modal.periods.component.html',
    styleUrls:  ['modal.periods.component.scss']
})

export class ModalPeriodsComponent { // 2017-11-16 modificato
    /*
    extends MatDialogRef<ConfirmModel, { confirmed: boolean, values: any[] }>
    implements ConfirmModel {
    */

    private title:          string;
    private periodsInput:   any[];
    private periods:        any[] = [];
    private selPeriods:  number[] = [];
    private policiesList:   any[] = [];
    private periodsList:    any[] = [];
    private policySel:      any = 'all';

    constructor( // 2017-11-16 modificato
        public dialogService: MatDialogRef<ModalPeriodsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private lookupService: LookupService,
        private profileService: ProfileService
    ) {
        this.title        = data.title;
        this.periodsInput = data.periodsInput;
        /* super(dialogService); */
    }

    confirm() {
        let values: any[] = [];
        fn.sortBy( this.periodsList, 'DATA_INIZIO' ); // ordine crescente
        for(let i=0;i<this.periodsList.length;i++ ) {
            if (this.selPeriods.indexOf(this.periodsList[i].value) >= 0){
                values.push(this.periodsList[i]);
            }
        }
        this.dialogService.close({ confirmed: true, values: values }); // 2017-11-17 modificato
    }

    ngOnInit(){
        this.lookupService.getFromDb('periods')
            .subscribe(
                response => {
                    this.periods = response.body;
                    fn.sortBy( this.periods, 'DATA_INIZIO', true ); // ordine decrescente
                    let j=0;
                    let k=0;
                    let politicaCod = -1;
                    for(let i=0;i<this.periods.length;i++){
                        const entry = this.periods[i];
                        if(entry.POLITICA_COD != politicaCod) {
                            this.policiesList[j] = {value: entry.POLITICA_COD, display: entry.POLITICA_DESC};
                            j++;
                            politicaCod = entry.POLITICA_COD;
                        }

                        this.periodsList[k] = {
                            policy:              politicaCod,
                            value:               entry.PERIODO_COD,
                            display:             entry.PERIODO_DESC,
                            PERIODO_COD:         entry.PERIODO_COD,
                            PERIODO_DESC:        entry.PERIODO_DESC,
                            POLITICA_COD:        entry.POLITICA_COD,
                            POLITICA_DESC:       entry.POLITICA_DESC,
                            DATA_INIZIO:         entry.DATA_INIZIO,
                            DATA_FINE:           entry.DATA_FINE,
                            POLITICA_COLORE_COD: entry.POLITICA_COLORE_COD,
                            NUMERO_GIORNI:       entry.NUMERO_GIORNI,
                            NUMERO_SETTIMANE:    entry.NUMERO_SETTIMANE,
                        };
                        k++;
                    }

                    let arrSelected:any[] = [];
                    if(this.periodsInput != null && this.periodsInput.length>0){
                        this.periodsInput.forEach(function(element) {
                            arrSelected.push(element.value);
                        });
                    }
                    this.selPeriods = arrSelected;
                    this.profileService.refreshToken(response.headers); // --- 2017-11-24
                },
                err => {
                    console.error(err);
                },
            );
    }

    getPeriodsList(){
        let pList: any[] = [];
        let j=0;
        for(var i=0; i<this.periodsList.length; i++){
            if(this.periodsList[i].policy==this.policySel || this.policySel=='all') {
                pList[j] = this.periodsList[i];
                j++;
            }
        }
        return pList;
    }

    selectPeriod(period:any){
        const indexElem = this.selPeriods.indexOf(period);

        if(indexElem == -1) {
            this.selPeriods.push(period);
        }else{
            this.selPeriods.splice(indexElem, 1);
        }
    }

    checkSelectPeriod(period:any){
        if(this.selPeriods.indexOf(period)>=0){
            return true;
        }else{
            return false;
        }
    }

    setPolicy(policy:any){
        this.policySel = policy;
    }

    selectAll(){
        for(var i=0; i<this.periodsList.length; i++) {
            if(this.periodsList[i].policy==this.policySel || this.policySel=='all') {
                const indexElem = this.selPeriods.indexOf(this.periodsList[i].value);
                if(indexElem==-1) {
                    this.selPeriods.push(this.periodsList[i].value);
                }
            }
        }
    }

    checkSelectedPolicy(policy:any){
        if(policy == this.policySel){
            return true;
        }else{
            return false;
        }
    }

    deSelectAll(){
        for(var i=0; i<this.periodsList.length; i++) {
            if(this.periodsList[i].policy==this.policySel || this.policySel=='all') {
                const indexElem = this.selPeriods.indexOf(this.periodsList[i].value);
                this.selPeriods.splice(indexElem, 1);
            }
        }
    }
}
