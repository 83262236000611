import { Component, OnInit, ElementRef  } from '@angular/core';
import { Router, ActivatedRoute         } from '@angular/router';
import { environment                    } from '../environments/environment';

declare var $: any;
@Component({
    selector: 'my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    constructor(private elRef: ElementRef) {}
    ngOnInit() {
        console.info('Tyrion version: ' + environment.version + '  (Angular ' + $('my-app').attr('ng-version') + ' Material 8.1.3)');
        const body = document.getElementsByTagName('body')[0];
        const isWindows = navigator.platform.indexOf('Win') > -1;
        if (isWindows) {
           // if we are on windows OS we activate the perfectScrollbar function
            body.classList.add('perfect-scrollbar-on');
        } else {
            body.classList.add('perfect-scrollbar-off');
        }
        $.material.init();
    }
}
