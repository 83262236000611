import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ProfileComponent } from './profile.component';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'dashboard/editorial',
      pathMatch: 'full',
    },
    {
      path: '',
      component: AdminLayoutComponent,
      children: [
        {
            path: 'dashboard',
            loadChildren: './dashboard/dashboard.module#DashboardModule',
            canActivate: [ProfileComponent],
        },
        {
            path: 'export',
            loadChildren: './export/export.module#ExportModule',
            canActivate: [ProfileComponent],
        },
        {
            path: 'import',
            loadChildren: './import/import.module#ImportModule',
            canActivate: [ProfileComponent],
        },
        {
            path: 'settings',
            loadChildren: './settings/settings.module#SettingsModule',
            canActivate: [ProfileComponent],
        },
        {
            path: 'system',
            loadChildren: './system/system.module#SystemModule',
        },
        {
            path: 'test',
            loadChildren: './test/test.module#TestModule',
        },
      ]
    },
    {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
    }
];
