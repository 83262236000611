import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {AlertService} from "./alert.service";
import {messages} from "../../environments/messages";

@Injectable()
export class ProfileService {
    private sessionToken: string = '';
    private username: string = '';
    private name: string = '';
    private surname: string = '';
    private sessionPrivileges: {};

    constructor(    private http:                   HttpClient,
                    private router:                 Router,
                    private alertService:           AlertService ) {
        this.initSession();
    }

    getUsername() {
        return this.username;
    }

    getName() {
        return this.name;
    }

    getSurname() {
        return this.surname;
    }

    getPrivileges() {
        return this.sessionPrivileges;
    }

    login(username, password): Observable<any> {
        const srvUrl: string = environment.srvUrl;
        const headers = new HttpHeaders().set('Content-Type', `application/json`);
        return this.http.post(`${environment.srvUrl}/logins`, JSON.stringify({ username, password }), { headers })
    }

    forgot(email): Observable<any> {
        const srvUrl: string = environment.srvUrl;
        const headers = new HttpHeaders().set('Content-Type', `application/json`);
        return this.http.post(`${environment.srvUrl}/forgot`, JSON.stringify({ email }), { headers, observe: 'response' })
    }

    changePassword(old, new1): Observable<any> {
        let authToken = localStorage.getItem('session_token');
        const url = `${environment.srvUrl}/changepsw?OLD=${old}&NEW=${new1}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(url, { headers, observe: 'response' })
    }

    logout() {
        this.sessionToken = '';
        this.username = '';
        this.name = '';
        this.surname = '';
        this.sessionPrivileges = {};
        localStorage.clear();
        window.location.reload(true);
    }

    isLoggedIn(): Observable<any> {
        let authToken = localStorage.getItem('session_token');
        const url = `${environment.srvUrl}/is-logged-in`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(url, { headers });
    }

    public initSession() {
        if (localStorage.getItem('session_username')) {
            this.username = localStorage.getItem('session_username');
        }
        if (localStorage.getItem('session_name')) {
            this.name = localStorage.getItem('session_name');
        }
        if (localStorage.getItem('session_surname')) {
            this.surname = localStorage.getItem('session_surname');
        }
        if (localStorage.getItem('session_token')) {
            this.sessionToken = localStorage.getItem('session_token');
        }
        if (localStorage.getItem('session_privileges')) {
            this.sessionPrivileges = JSON.parse(localStorage.getItem('session_privileges'));
        }
    }

    public refreshToken(res: HttpHeaders) {
        if (res.get('X-Access-Token') != null && res.get('X-Access-Token').trim() !== '') {
            localStorage.setItem('session_token', res.get('X-Access-Token'));
        }
    }

    public serviceError(error: any, alert:boolean=false){
        console.error(error);
        const errorCode = error.status;
        switch(errorCode) {
            case 401: //authentication error
                this.logout();
                this.router.navigate(['pages/login']);
                break;
            case 403: //permission denied
                if(alert){
                    this.alertService.showNotification(messages[`msg_access_denied`], 'danger');
                }else {
                    this.router.navigate(['system/noauth']);
                }
                break;
            default: //default
                if(alert){
                    this.alertService.showNotification(messages[`msg_unknown_error`], 'danger');
                }else {
                    this.router.navigate(['system/error']);
                }
                break;
        }
    }

}
