import { RouteInfo } from './sidebar.metadata';

export const ROUTES: any = {
    dashboard: [
        { path: '/dashboard/editorial',         title: 'Editorial',                     icon: '', prefix: 'ED'  },
        { path: '/dashboard/advertising',       title: 'Advertising',                   icon: '', prefix: 'AD'  },
        { path: '/dashboard/ratecards',         title: 'Ratecards Estimates',           icon: '', prefix: 'RE'  }
    ], import: [
        { path: '/import/calendar',             title: 'Calendar',                      icon: '', prefix: 'CA'  },
        { path: '/import/editorial-history',    title: 'Editorial History import',      icon: '', prefix: 'EH'  },
        { path: '/import/editorial-estimates',  title: 'Editorial Estimates import',    icon: '', prefix: 'EE'  },
        { path: '/import/adv-history',          title: 'Adv History import',            icon: '', prefix: 'AH'  },
        { path: '/import/adv-estimates',        title: 'Adv Estimates import',          icon: '', prefix: 'AE'  },
        { path: '/import/palimpsest',           title: 'Programme Schedule import',     icon: '', prefix: 'PS'  }
    ], export: [
        { path: '/export/ratecards-bts',        title: 'BTS export',                    icon: '', prefix: 'BE'  },
        { path: '/export/adv-kubik',            title: 'Kubik export',                  icon: '', prefix: 'KE'  },
    ], settings: [
        { path: '/settings/periods',            title: 'Time Periods',                  icon: '', prefix: 'TP'  },
        { path: '/settings/policies',           title: 'Policies',                      icon: '', prefix: 'PO'  },
        { path: '/settings/daypartconfs',       title: 'Configurations',                icon: '', prefix: 'CO'  },
        { path: '/settings/daypartgroups',      title: 'Daypart Groups',                icon: '', prefix: 'DG'  },
        { path: '/settings/businesstypes',      title: 'Business Types',                icon: '', prefix: 'BT'  },
//        { path: '/settings/businesstypegroups', title: 'Business Type Groups',          icon: '', prefix: 'BG'  },
        { path: '/settings/targets',            title: 'Targets',                       icon: '', prefix: 'TA'  },
        { path: '/settings/universes',          title: 'Targets Universes',             icon: '', prefix: 'TU'  },
        { path: '/settings/channels',           title: 'Channels',                      icon: '', prefix: 'CH'  },
        { path: '/settings/channelgroups',      title: 'Channel Groups',                icon: '', prefix: 'CG'  },
    ], system: [
        { path: '/system/noauth',               title: 'Access denied',                 icon: '', prefix: ''    },
        { path: '/system/error',                title: 'Error',                         icon: '', prefix: ''    },
        { path: '/system/changepsw',            title: 'Change Password',               icon: '', prefix: ''    },
        { path: '/test/test1',                  title: 'Test 1',                        icon: '', prefix: ''    },
        { path: '/test/test2',                  title: 'Test 2',                        icon: '', prefix: ''    },
        { path: '/test/test3',                  title: 'Test 3',                        icon: '', prefix: ''    }
    ]
};
