// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    version: 					'2023-03-15',
    production: 				true,
    srvUrl: 					'https://backend.tyrion.systrategy.com/polcomm',
    historyEditorialFileName: 	['history_editorial.xlsx'],
    advertisingFileName: 		'advertising',
    advtargetsFileName: 		'adv-targets',
    advratecardsFileName: 		'adv-ratecards',
    titlePrefixProject: 		'das',
    titleProject: 				'Marianna',
    debug: 						false
};
