import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
    /*
    transform(input: number){
        let retVal: string='';

        if(input != null) {
            const i = input.toFixed(2);
            retVal = parseFloat(i.toString()).toLocaleString('it-IT');
        }else{
            retVal = '';
        }

        return retVal;
    }

    */

    transform( input: number) {
        if ( input != null ) {
            // Elimino i caratteri che non sono numeri (lascio il segno meno e il punto)
            let stringNum = input.toString().replace(/[^0-9\.\-]?/gi, '');
            if ( !isNaN(parseInt(stringNum, 10)) && parseInt(stringNum, 10) > -1 ) {
                // Aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                if ( stringNum.length > 3 ) { stringNum = stringNum.replace(/\B(?=(?:\d{3})+(?!\d))/g, '.'); }
                return stringNum;
            }
        }
        return '';
    }

}
