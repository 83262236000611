import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ProfileService } from './profile.service';
import { Observable, of } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {DaypartBts} from '../settings/daypartbases/daypartbts';


@Injectable()
export class LookupService {

    constructor(private http: HttpClient, private profileService: ProfileService) {}

    getFromDb(relativeUrl): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('session_token'));
        return this.http.get( environment.srvUrl + '/lookup/' + relativeUrl, { headers, observe: 'response' });
    }

}
