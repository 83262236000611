import { NgModule } from '@angular/core';
import { FormatDateDBPipe } from './format-date-db.pipe';
import { FormatDateCalendarPipe } from './format-date-calendar.pipe';
import { FormatNumberPipe } from './format-number.pipe';

@NgModule({
    imports:      [
    ],
    declarations: [
        FormatDateDBPipe,
        FormatDateCalendarPipe,
        FormatNumberPipe,
    ],
    exports: [
        FormatDateDBPipe,
        FormatDateCalendarPipe,
        FormatNumberPipe,
    ],
    providers: [
        FormatDateDBPipe,
        FormatDateCalendarPipe,
        FormatNumberPipe
    ]
})
export class PipesModule { }
