import { Injectable } from '@angular/core';
import * as moment from 'moment';

declare var $:any;

@Injectable()
export class AlertService {

    showNotification( message, type, from = 'top', align = 'center' ){
        $.notify(
            {
                icon:       'notifications',
                message:    '(' + moment().format('HH:mm') + ')  ' + message
            },{
                type,
                delay:      0, // il pop-up di notifica rimane permanentemente visibile finché l'utente non lo chiude
                placement:  {
                    from,
                    align
                }
            }
        );
    }

}
