import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { LookupService } from '../../srv/lookup.service';
import { ProfileService } from '../../srv/profile.service';
import * as fn from '../../srv/debug.service';

declare var $:any;

@Component({
    selector:    'modal-periods-link',
    templateUrl: 'modal.periods-link.component.html',
    styleUrls:  ['modal.periods-link.component.scss'],
})

export class ModalPeriodsLinkComponent {
    title:              string  = '';
    periodsInput:       any[]   = [];
    periodsInputToLink: any[]   = [];
    periods:            any[]   = [];

    selPeriods:         any[]   = []; // -OLD- { estimate: number, history: number }[] = [];
    selPeriodEstimate:  number  = -1;
    selPeriodHistory:   number  = -1;

    policiesList:       any[]   = [];
    periodsList:        any[]   = [];
    policySel:          any     = 'all';
    hashPeriods:        any[]   = [];

    constructor(
        public dialogService:   MatDialogRef<ModalPeriodsLinkComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private lookupService:  LookupService,
        private profileService: ProfileService
    ) {
        this.title              = data.title;
        this.periodsInput       = data.periodsInput;        // periodi da stimare
        this.periodsInputToLink = data.periodsInputToLink;  // periodi di storico
    }

    confirm() {
        let retValues: any[] = [];
        const hashPeriodsApp = this.hashPeriods;
        this.selPeriods.forEach(function(element) {
            if(element != null) {
                const displayValue = `${hashPeriodsApp[element.estimate].PERIODO_DESC} (${hashPeriodsApp[element.history].PERIODO_DESC})`;
                const objPeriod = {
                    value:                  element.estimate,
                    valueLink:              element.history,
                    display:                displayValue,
                    PERIODO_COD:            element.estimate, // codice periodo da stimare
                    PERIODO_DESC:           hashPeriodsApp[element.estimate].PERIODO_DESC, // descrizione periodo da stimare
                    DATA_INIZIO:            hashPeriodsApp[element.estimate].DATA_INIZIO,
                    DATA_FINE:              hashPeriodsApp[element.estimate].DATA_FINE,
                    NUMERO_GIORNI:          hashPeriodsApp[element.estimate].NUMERO_GIORNI,
                    NUMERO_SETTIMANE:       hashPeriodsApp[element.estimate].NUMERO_SETTIMANE,
                    POLITICA_COD:           hashPeriodsApp[element.estimate].POLITICA_COD,
                    POLITICA_DESC:          hashPeriodsApp[element.estimate].POLITICA_DESC,
                    POLITICA_COLORE_COD:    hashPeriodsApp[element.estimate].POLITICA_COLORE_COD,
                    PERIOD_TO_COMPARE_COD:  element.history,
                    PERIOD_TO_COMPARE_DESC: hashPeriodsApp[element.history].PERIODO_DESC // descrizione periodo collegato a quello da stimare
                };
                retValues.push(objPeriod);
            }
        });
        retValues.sort(function(a, b) { return a.DATA_INIZIO - b.DATA_INIZIO; });
        this.dialogService.close({ confirmed: true, values: retValues });
    }

    ngOnInit(){
        this.lookupService.getFromDb('periods')
            .subscribe(
                response => {
                    this.periods = response.body;
                    fn.sortBy( this.periods, 'DATA_INIZIO', true );
                    let j           = 0;
                    let k           = 0;
                    let politicaCod = -1;
                    for(let i = 0; i < this.periods.length; i++){   // scorre tutti i periodi
                        const entry              = this.periods[i]; // singolo periodo (dall'elenco generale) con cui fare confronti
                        const inPeriodsToLink    = this.periodsInputToLink.find(
                            period => period.value === entry.PERIODO_COD );
                        // se entry.PERIODO_COD è uguale al value dei periodsInputToLink

                        if(entry.POLITICA_COD != politicaCod && inPeriodsToLink == null) {
                            this.policiesList[j] = { value: entry.POLITICA_COD, display: entry.POLITICA_DESC };
                            j++;
                            politicaCod          = entry.POLITICA_COD;
                        }

                        if( inPeriodsToLink == null ) {
                            // const objPeriod = {policy: politicaCod, value: entry.PERIODO_COD, display: entry.PERIODO_DESC};
                            this.periodsList[k] = { // ---- 14/02/2018 ---- MODIFICA Maurizio per avere più info in advertising
                                policy:             politicaCod,
                                value:              entry.PERIODO_COD,
                                display:            entry.PERIODO_DESC,
                                PERIODO_COD:        entry.PERIODO_COD,
                                PERIODO_DESC:       entry.PERIODO_DESC,
                                POLITICA_COD:       entry.POLITICA_COD,
                                POLITICA_DESC:      entry.POLITICA_DESC,
                                DATA_INIZIO:        entry.DATA_INIZIO,
                                DATA_FINE:          entry.DATA_FINE,
                                POLITICA_COLORE_COD:entry.POLITICA_COLORE_COD,
                                NUMERO_SETTIMANE:   entry.NUMERO_SETTIMANE,
                                NUMERO_GIORNI:      entry.NUMERO_GIORNI
                            };
                            k++;
                        }

                        this.hashPeriods[entry.PERIODO_COD] = entry;
                    }

                    let arrSelected: { estimate: number, history: number }[] = [];
                    if(this.periodsInput != null && this.periodsInput.length > 0){
                        this.periodsInput.forEach(function(element) {
                            arrSelected[element.value]  = { estimate: element.value, history: element.valueLink };
                        });
                    }
                    this.selPeriods = arrSelected;
                    this.profileService.refreshToken(response.headers);
                }, err => { console.error(err); },
            );
    }

    getPeriodsList(){ // filtro di selezione (specifico in base alla policy selezionata oppure tutte se 'all')
        let pList: any[] = [];
        let j = 0;
        for( var i = 0; i < this.periodsList.length; i++){
            if( this.periodsList[i].policy == this.policySel || this.policySel == 'all') {
                pList[j] = this.periodsList[i];
                j++;
            }
        }
        return pList;
    }

    setPolicy(policy: any){ this.policySel = policy; }

    selectPeriodLink(event, period: any, i: number){
       /* setTimeout(function(){
           document.getElementById("rowP" + i + "head").scrollIntoView();
           }, 50);  ------------- NON FUNZIONA COME CI SI ASPETTA --------------- */
       if(period == this.selPeriodEstimate){
            this.selPeriodEstimate = -1;
       }else{
            if(this.periodsInputToLink.length>0) {
                this.selPeriodEstimate = period;
            }
       }
    }

    checkArrowHide() {
        if(this.periodsInputToLink.length>0) {
            return false;
        }else{
            return true;
        }
    }

    selectPeriodHistory(period: any){
        this.selPeriods[this.selPeriodEstimate] = {
            estimate: this.selPeriodEstimate,
            history: period
        };
    }

    removePeriodLink(period: number){
        delete this.selPeriods[period];
        this.selPeriodEstimate = -1;
    }

    selectPeriodEstimate(period:any){
        this.selPeriodEstimate = period;

        if(this.selPeriods.length > 0) {
            const selPeriodEstimate = this.selPeriodEstimate;
            const check = this.selPeriods.filter(function (item) {
                return item.estimate === selPeriodEstimate;
            });
            if(check.length>0) {
                this.selPeriodHistory = check[0].history;
            }
        }
    }

    checkPeriodHistoryLinked(period: any){
        const selPeriodEstimate = this.selPeriodEstimate;
        const check = this.selPeriods.filter(function(item) {
            return item.estimate === selPeriodEstimate && item.history === period;
        });
        return check.length > 0;
    }

}
