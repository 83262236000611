import { RouterModule                           } from '@angular/router';
import { HttpClientModule                       } from '@angular/common/http';
import { CommonModule                           } from '@angular/common';
import { platformBrowserDynamic                 } from '@angular/platform-browser-dynamic';
import { BrowserModule                          } from '@angular/platform-browser';
import { BrowserAnimationsModule                } from '@angular/platform-browser/animations';
import { NgModule                               } from '@angular/core';
import { FormsModule, ReactiveFormsModule       } from '@angular/forms';
import {
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
                                                } from '@angular/material';
import { AppComponent                           } from './app.component';

import { SidebarModule                          } from './sidebar/sidebar.module';
import { FooterModule                           } from './shared/footer/footer.module';
import { NavbarModule                           } from './shared/navbar/navbar.module';
import { SpinnerModule                          } from './shared/spinner/spinner.module';
import { AdminLayoutComponent                   } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent                    } from './layouts/auth/auth-layout.component';
import { AppRoutes                              } from './app.routing';

import { DebugService                           } from './srv/debug.service';
import { CommonService                          } from './srv/common.service';
import { ProfileService                         } from './srv/profile.service';
import { LookupService                          } from './srv/lookup.service';
import { AlertService                           } from './srv/alert.service';
import { PipesModule                            } from './pipes/pipes.module';
import { BootstrapModalModule                   } from 'ng2-bootstrap-modal';
import { ModalPeriodsComponent                  } from './modals/modal-periods/modal.periods.component';
import { ModalPeriodsLinkComponent              } from './modals/modal-periods-link/modal.periods-link.component';
import { SpinnerService                         } from './shared/spinner/spinner.service';
import { ProfileComponent                       } from './profile.component';

@NgModule({
    imports:      [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, { useHash: true } ),
        HttpClientModule,
        SidebarModule,
        FormsModule,
        ReactiveFormsModule,
        NavbarModule,
        FooterModule,
        SpinnerModule,
        PipesModule,
        BootstrapModalModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatIconModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        ModalPeriodsComponent,
        ModalPeriodsLinkComponent,
    ],
    providers: [
        DebugService,
        CommonService,
        ProfileService,
        AlertService,
        LookupService,
        SpinnerService,
        ProfileComponent
    ],
    entryComponents: [
        ModalPeriodsComponent,
        ModalPeriodsLinkComponent,
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
