import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable         } from '@angular/core';
import { Observable, of     } from 'rxjs';
import { map, catchError    } from 'rxjs/operators';
import { ProfileService     } from './srv/profile.service';

@Injectable()
export class ProfileComponent implements CanActivate {

    constructor(private profileService: ProfileService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.profileService.isLoggedIn().pipe(
            map(e => { if (e) { return true; } }),
            catchError(() => {
                this.router.navigate(['pages/login']);
                return of(false);
            })
        );
    }


}
