export const messages = {
  // system
  msg_access_denied                     : 'Access denied - It seems You are not authorized to access this page',
  msg_unknown_error                     : 'Sorry, an error occurred while processing your request',
  msg_mod_psw_0                         : 'Password updated',
  error_mod_psw_1                       : 'Unexpected error',
  error_mod_psw_2                       : 'No Privilege available',
  error_mod_psw_3                       : 'New password must be between 8 and 50 characters',
  error_mod_psw_4                       : 'Invalid old password',
  msg_mod_forgot_0                      : 'Password sent via Email',
  error_mod_forgot_1                    : 'Unexpected error',
  error_mod_forgot_2                    : 'Email not found', // riga aggiunta per restituire errore se la select è vuota
  error_mod_forgot_3                    : 'New password must be between 8 and 50 characters',
  error_mod_forgot_4                    : 'Invalid user ',
  error_mod_forgot_24                   : 'No Privilege available',
  error_upload_001                      : 'is not a valid file name',
  error_upload_002                      : 'An error has occurred while uploading',


    // periods
  msg_mod_periods_0                     : 'Time Period saved',
  error_mod_periods_0                   : 'Unexpected error',
  error_mod_periods_1                   : 'Description of the Time Period has not been specified',
  error_mod_periods_2                   : 'Description of the Time Period already exists',
  error_mod_periods_3                   : 'Begin Date was not specified or is invalid',
  error_mod_periods_4                   : 'End Date was not specified or is invalid',
  error_mod_periods_5                   : 'Policy is not valid',
  error_mod_periods_6                   : 'Time Period (begin-end) is overlapping with an existing one',
  error_mod_periods_24                  : 'No Privilege available',

  msg_ins_periods_0                     : 'Time Period added',
  error_ins_periods_0                   : 'Unexpected error',
  error_ins_periods_1                   : 'Description of the Time Period has not been specified',
  error_ins_periods_2                   : 'Description of the Time Period already exists',
  error_ins_periods_3                   : 'Begin Date was not specified or is invalid',
  error_ins_periods_4                   : 'End Date was not specified or is invalid',
  error_ins_periods_5                   : 'Policy is not valid',
  error_ins_periods_6                   : 'Time Period (begin-end) is overlapping with an existing one',
  error_ins_periods_24                  : 'No Privilege available',

  msg_del_periods_0                     : 'Time Period deleted',
  msg_del_periods_1                     : 'Do you want to delete the selected Time Period?',
  error_del_periods_0                   : 'Unexpected error',
  error_del_periods_24                  : 'No Privilege available',

  // policies
  msg_mod_policy_0                      : 'Policy saved',
  error_mod_policy_0                    : 'Unexpected error',
  error_mod_policy_1                    : 'Description of the Policy has not been specified',
  error_mod_policy_2                    : 'Description of the Policy already exists',
  error_mod_policy_24                   : 'No Privilege available',

  msg_ins_policy_0                      : 'Policy added',
  error_ins_policy_0                    : 'Unexpected error',
  error_ins_policy_1                    : 'Description of the Policy has not been specified',
  error_ins_policy_2                    : 'Description of the Policy already exists',
  error_ins_policy_24                   : 'No Privilege available',

  msg_del_policy_0                      : 'Policy deleted',
  msg_del_policy_1                      : 'Do you want to delete the selected Policy?',
  error_del_policy_0                    : 'Unknow error',
  error_del_policy_24                   : 'No Privilege available',

  // channel groups
  msg_mod_channelgroups_0               : 'Channel Group saved',
  msg_ins_channelgroups_0               : 'Channel Group added',
  msg_del_channelgroups_0               : 'Channel Group deleted',
  msg_del_channelgroups_1               : 'Do you want to delete the selected Channel Group?',


  // channels
    msg_move_channel_0                  : 'Channel moved',
  error_move_channel_0                  : 'Channel move: Unexpected error',
  error_move_channel_24                 : 'No Privilege available',

    msg_mod_channel_0                   : 'Channel modified',
  error_mod_channel_0                   : 'Unexpected error',
  error_mod_channel_1                   : 'No parameter specified',
  error_mod_channel_3                   : 'Description of the Channel already exists',
  error_mod_channel_4                   : 'Channel Group is invalid',
  error_mod_channel_5                   : 'Discovery Channel is invalid',
  error_mod_channel_24                  : 'No Privilege available',

    msg_ins_channel_0                   : 'Channel added',
  error_ins_channel_010                 : 'Unexpected error',
  error_ins_channel_011                 : 'No Channel Description specified',
  error_ins_channel_013                 : 'Description of the Channel already exists',
  error_ins_channel_015                 : 'Discovery Channel is invalid',
  error_ins_channel_034                 : 'No Privilege available',

  // daypartConfs
  msg_mod_daypartConfs_0                : 'Base Daypart Configuration saved',
  error_mod_daypartConfs_0              : 'Unexpected error',
  error_mod_daypartConfs_1              : 'Description of the Base Daypart Configuration has not been specified',
  error_mod_daypartConfs_2              : 'Description of the Base Daypart Configuration already exists',
  error_mod_daypartConfs_3              : 'Begin Date was not specified or is invalid',
  error_mod_daypartConfs_4              : 'End Date was not specified or is invalid',
  error_mod_daypartConfs_5              : 'Base Daypart Configuration (begin-end) is overlapping with an existing one',
  error_mod_daypartConfs_6              : 'Daypart Configuration code is not valid',
  error_mod_daypartConfs_24             : 'No Privilege available',

  msg_ins_daypartConfs_0                : 'Base Daypart Configuration added',
  error_ins_daypartConfs_0              : 'Unexpected error',
  error_ins_daypartConfs_1              : 'Description of the Base Daypart Configuration has not been specified',
  error_ins_daypartConfs_2              : 'Description of the Base Daypart Configuration already exists',
  error_ins_daypartConfs_3              : 'Begin Date was not specified or is invalid',
  error_ins_daypartConfs_4              : 'End Date was not specified or is invalid',
  error_ins_daypartConfs_5              : 'Base Daypart Configuration (begin-end) is overlapping with an existing one',
  error_ins_daypartConfs_24             : 'No Privilege available',

  msg_del_daypartConfs_0                : 'Base Daypart Configuration deleted',
  msg_del_daypartConfs_1                : 'Do you want to delete the selected Base Daypart Configuration?',
  error_del_daypartConfs_0              : 'Unexpected error',
  error_del_daypartConfs_6              : 'Daypart Configuration code is not valid',
  error_del_daypartConfs_24             : 'No Privilege available',

  daypartgroups                         : {
      ins: {
          success: {  1   : 'Daypart Group added' },
          error:   {
                      0   : 'Unexpected error',
                      1   : 'Description of the Daypart Group has not been specified',
                      2   : 'Description of the Daypart Group already exists',
                      24  : 'No Privilege available',
          }
      },
      mod: {
          success: {  1   : 'Daypart Group modified' },
          error:   {
                      0   : 'Unexpected error',
                      1   : 'Description of the Daypart Group has not been specified',
                      2   : 'Description of the Daypart Group already exists',
                      6   : 'Daypart Group code is not valid',
                      24  : 'No Privilege available',
          }
      },
      del: {
          confirm: {  0   : 'Do you want to delete the selected Daypart Group?' },
          success: {  1   : 'Daypart Group deleted' },
          error:   {
                      0   : 'Unexpected error',
                      1   : 'Description of the Daypart Group has not been specified',
                      6   : 'Daypart Group code is not valid',
                      24  : 'No Privilege available',
          }
      },
      mov: {
          error:   {
                      0   : 'Unexpected error',
                      1   : 'Description of the Daypart Group has not been specified',
                      2   : 'Description of the Daypart Group already exists',
                      6   : 'Daypart Group code is not valid',
                      24  : 'No Privilege available',
          }
      }
  },


  dayparts                            : {
      ins: {
          success: {  1   : 'Daypart added in group' },
          error:   {
              0   : 'Unexpected error',
              1   : 'Daypart not found',
              2   : 'Daypart Group not found',
              24  : 'No Privilege available',
          }
      },
      del: {
          confirm: {  0   : 'Do you want to remove the selected Daypart from its group?' },
          success: {  1   : 'Daypart removed from Group' },
          error:   {
              0   : 'Unexpected error',
              1   : 'Daypart not found',
              2   : 'Daypart Group not found',
              24  : 'No Privilege available',
          }
      },
      mov: {
          error:   {
              0   : 'Unexpected error',
              1   : 'Daypart not found',
              2   : 'Daypart Group not found',
              24  : 'No Privilege available',
          }
      }
  },


  // base dayparts
  msg_mod_dayparts_0                    : 'Base Daypart saved',
  error_mod_dayparts_0                  : 'Unexpected error',
  error_mod_dayparts_1                  : 'Daypart Configuration code is not valid',
  error_mod_dayparts_2                  : 'Base Daypart code is not valid',
  error_mod_dayparts_3                  : 'Begin Hour was not specified or is invalid',
  error_mod_dayparts_4                  : 'End Hour was not specified or is invalid',
  error_mod_dayparts_5                  : 'End Hour is lower than Begin Hour',
  error_mod_dayparts_6                  : 'Base Daypart (begin-end) is overlapping with an existing one',
  error_mod_dayparts_24                 : 'No Privilege available',

  msg_ins_dayparts_0                    : 'Base Daypart added',
  error_ins_dayparts_0                  : 'Unexpected error',
  error_ins_dayparts_1                  : 'Daypart Configuration code is not valid',
  error_ins_dayparts_2                  : 'Base Daypart code is not valid',
  error_ins_dayparts_3                  : 'Begin Hour was not specified or is invalid',
  error_ins_dayparts_4                  : 'End Hour was not specified or is invalid',
  error_ins_dayparts_5                  : 'End Hour is lower than Begin Hour',
  error_ins_dayparts_6                  : 'Base Daypart (begin-end) is overlapping with an existing one',
  error_ins_dayparts_24                 : 'No Privilege available',

  msg_del_dayparts_0                    : 'Base Daypart deleted',
  msg_del_dayparts_1                    : 'Do you want to delete the selected Base Daypart ?',
  error_del_dayparts_0                  : 'Unexpected error',
  error_del_dayparts_1                  : 'Base Daypart not found in current Daypart Configuration',
  error_del_dayparts_24                 : 'No Privilege available',

  msg_move_dayparts_0                   : 'Daypart moved',
  error_move_dayparts_0                 : 'Daypart move: Unexpected error',
  error_move_dayparts_24                : 'No Privilege available',

  // mod stime ratecards ( MOD_STIMA_RATE_CARD )
  mod_stima_ratecard                    : {
    mod: {
      success: {  1   : 'Ratecard modified' },
      error:   {
                  0   : 'Unexpected error',
                  1   : 'Unexpected Discovery Channel',
                  2   : 'Unexpected Time Period',
                  3   : 'Unexpected Daypart',
                  4   : 'Unexpected Ratecard',
                  24  : 'No Privilege available',
      }
    },
    del: {
      success: {  1   : 'Ratecard deleted' },
      error:   {
                  0   : 'Unexpected error',
                  1   : 'Unexpected Discovery Channel',
                  2   : 'Unexpected Time Period',
                  3   : 'Unexpected Daypart',
                  4   : 'Unexpected Ratecard',
                  24  : 'No Privilege available',
      }
    },

  },


  // import editorial history ( LOAD_ARIANNA_STORICO_ED )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_editorial_history              : {
    success: {  1   : 'AMR History successfully imported' },
    error:   {
                0   : 'An error has occurred on Editorial History import',
                1   : 'Unexpected Channel found',
                2   : 'Unexpected Target found',
                3   : 'Unexpected Hour Range found',
                4   : 'Error on AMR Channel delete',
                5   : 'Error on AMR Total TV delete',
                6   : 'Error on AMR Channel insert',
                7   : 'Error on AMR Total TV insert',
                24  : 'No Privilege available'
    }
  },

  // import editorial estimates ( LOAD_STIME_ED )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_editorial_estimates            : {
    success: {  1   : 'AMR Estimates successfully imported' },
    error:   {
                0   : 'An error has occurred on Editorial Estimates import',
                1   : 'Unexpected Channel found',
                2   : 'Unexpected Target found',
                3   : 'Unexpected Time Period found',
                4   : 'Unexpected Hour Range found',
                5   : 'Error on AMR Channel delete',
                6   : 'Error on AMR Total TV delete',
                7   : 'Error on AMR unique Channel insert',
                8   : 'Error on AMR grouping Channel insert',
                9   : 'Error on AMR Total TV insert',
                24  : 'No Privilege available',
                200 : 'Hour range already added'
    }
  },

  // import adv history ( LOAD_ARIANNA_STORICO_ADV )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_adv_history              : {
    success: {  1   : 'ADV History successfully imported' },
    error:   {
                0   : 'An error has occurred on Advertising History import',
                1   : 'Unexpected Channel found',
                2   : 'Unexpected Target found',
                3   : 'Unexpected Business Type found',
                4   : 'Error on Daypart insert',
                5   : 'Unexpected Daypart found',
                6   : 'Error on contacts delete',
                7   : 'Error on contacts insert',
                8   : 'Error on calendar update',
                24  : 'No Privilege available'
    }
  },

  // import adv estimates ( LOAD_STIME_ADV )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_adv_estimates              : {
    success: {  1   : 'ADV Estimates successfully imported' },
    error:   {
                0   : 'An error has occurred on Advertising Estimates import',
                1   : 'No Timeslot found in current Configuration',
                2   : 'Unexpected Channel found',
                3   : 'Unexpected Target found',
                4   : 'Unexpected Time Period found',
                5   : 'Unexpected Timeslot found in current Configuration',
                6   : 'Unexpected Daypart found',
                7   : 'Error on deleting timeslot estimates',
                8   : 'Error on deleting daypart estimates',
                9   : 'Error import timeslot estimates for unique channels',
                10  : 'Error import daypart estimates for unique channels',
                11  : 'Error import timeslot estimates for grouping channels',
                12  : 'Error import daypart estimates for grouping channels',
                13  : 'Unexpected Daypart Type found',
                24  : 'No Privilege available'
    }
  },


  // import adv estimates ratecards ( LOAD_STIME_RATE_CARDS )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_adv_estimates_ratecards  : {
    success: {  1   : 'ADV Ratecards Estimates successfully imported' },
    error:   {
                0   : 'An error has occurred on Advertising Estimates import',
                1   : 'Unexpected Channel found',
                2   : 'Unexpected Time Period found',
                3   : 'Unexpected Daypart found',
                4   : 'Error on deleting ratecards estimates estimates',
                5   : 'Error on import ratecards estimates estimates for unique channels',
                6   : 'Error on import ratecards estimates estimates for grouping channels',
                24  : 'No Privilege available'
    }
  },


  // import palimpsest ( LOAD_DISCOVERY_RATE_CARDS )
  // non servono messaggi di errore perché li restituisce direttamente la store
  import_palimpsest                 : {
    success: {  1   : 'BTS Ratecards successfully imported' },
    error:   {
                0   : 'An error has occurred on BTS Ratecards import',
                1   : 'Begin Date was not specified or is invalid',
                2   : 'End Date was not specified or is invalid',
                3   : 'Begin Date is greater than End Date',
                4   : 'Unexpected Discovery Channel found',
                5   : 'Discovery Channels cannot be read (please check file validity)',
                6   : 'Discovery Channels\' Descriptions cannot be changed (please check file validity)',
                7   : 'Discovery Channels cannot be inserted (please check file validity)',
                8   : 'Dayparts cannot be inserted (please check file validity)',
                9   : 'Original Rate Cards cannot be deleted (please check file validity)',
                10  : 'Original Rate Cards cannot be inserted (please check file validity)',
                11  : 'Rate Cards cannot be deleted (please check file validity)',
                12  : 'Rate Cards headers cannot be deleted (please check file validity)',
                13  : 'Rate Cards headers cannot be inserted (please check file validity)',
                14  : 'Rate Cards cannot be inserted (please check file validity)',
    }
  },



  // export xml ratecards per BTS
  msg_xml_ratecards_1                   : 'file successfully created',
  msg_xml_ratecards_0                   : 'Unexpected error',
  msg_xml_ratecards_024                 : 'No Privilege available',

  // export csv ratecards e adv per Kubik
  msg_kubik_export_1                    : 'file successfully created',
  error_kubik_export_0                  : 'Unexpected error',
  error_kubik_export_024                : 'No Privilege available',

};
